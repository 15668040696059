<!--
WARNING!!! don't pass $listeners directly to the v-text-field, it will emit the input event and will break the outside v-model
-->

<template>
	<v-text-field
		ref="input"
		v-bind="$attrs"
		v-model="inputModel"
		:hint="noHint ? null : format"
		:rules="rules"
		@blur="onInputBlur"
		@keydown.exact.esc="onInputCancel"
		@focus="$event.target.select()"
	>
		<template #append v-if="!$attrs.disabled">
			<v-menu
				ref="menu"
				v-model="menuModel"
				:close-on-content-click="false"
				offset-y
				left
				max-width="290px"
				min-width="290px"
			>
				<template #activator="{attrs,on}">
					<v-icon v-bind="attrs" v-on="on">{{pickerIcon}}</v-icon>
				</template>
				<v-date-picker
					ref="picker"
					first-day-of-week="1"
					:locale="locale"
					v-model="pickerModel"
					no-title
					@input="onPickerInput"
				></v-date-picker>
			</v-menu>
		</template>
	</v-text-field>
</template>

<script>
import moment from 'moment';

const formatISO = 'YYYY-MM-DD';

export default {
	props: {
		value: String,
		format: {
			type: String,
			default: window.ITTIConfig.inputDateFormat,
		},
		pickerIcon: {
			type: String,
			default: 'mdi-calendar',
		},
		locale: {
			type: String,
			default: 'bg',
		},
		noHint: Boolean,
	},
	data() {
		return {
			menuModel: false,
			inputModel: this.formatDate(this.value),
			pickerModel: this.value,
		}
	},
	computed: {
		rules() {
			return [
				value => !value || moment(value, this.format, true).isValid() || "Invalid date!",
				...(this.$attrs.rules || [])
			];
		},
		inputModelFiltered(){
			return this.inputModel?.toString().replace(',', '.') || '';
		},
	},
	watch: {
		value(val){
			this.pickerModel = val;
			this.inputModel = this.formatDate(this.pickerModel);
		},
	},
	methods: {
		formatDate(date) {
			if (date) {
				const md = moment(date, formatISO, true);
				if (md.isValid()) {
					date = md.format(this.format);
				}
			}
			return date || '';
		},
		formatDateISO(date) {
			if (date) {
				const md = moment(date, this.format, true);
				return md.isValid() ? md.format(formatISO) : '';
			}
			return '';
		},
		onInputBlur(){
			const value = this.formatDateISO(this.inputModelFiltered);
			if (this.inputModelFiltered && !value) {
				this.inputModel = this.formatDate(this.pickerModel);
				return;
			}
			this.pickerModel = value;
			this.inputModel = this.formatDate(value);
			this.$emit('input', value);
		},
		onInputCancel(){
			this.inputModel = this.formatDate(this.pickerModel);
		},
		onPickerInput(val){
			this.inputModel = this.formatDate(this.pickerModel);
			this.$emit('input', val);
			this.menuModel = false;
		},
	}
}
</script>